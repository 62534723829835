<template>
<!-- <svg xmlns="http://www.w3.org/2000/svg" width="1411" height="216" viewBox="0 0 1411 216" fill="none" preserveAspectRatio="none">
    <path d="M-1.63184 215.837L1412.09 215.837L1412.09 200.647C952.93 -59.9253 493.774 -71.6101 -1.63184 200.647L-1.63184 215.837Z" fill="#EEEEEE" fill-opacity="0.8"/>
</svg> -->
<section class="multimidias-home">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 mb-4">
                <div class="titulo-section text-center">
                    <h2>
                        Multimídias
                    </h2>
                </div>
            </div>
            <div class="inicial-multimidias">
                <div class="row">
                    <div class="col-lg-4" v-for="(imagem, index) in galMultimidias.slice(0,3)" :key="index">
                        <router-link :to="{name: 'Leitura Multimídia', params: {multimidia: imagem.slug}}" aria-label="Ler mais">
                            <div class="thumbMult" :style="{backgroundImage: `url(${imagem.capa})`}" alt="Multimídia 1"></div>
                        </router-link>
                    </div>

                </div>
            </div>
            <div class="full-multimidias" v-if="activeMult">
                <div class="row">
                    <div class="col-lg-4 mt-3" v-for="(imagem, index) in galMultimidias.slice(3)" :key="index">
                        <router-link :to="{name: 'Leitura Multimídia', params: {multimidia: imagem.slug}}" aria-label="Ler mais">
                            <div class="thumbMult" :style="{backgroundImage: `url(${imagem.capa})`}" alt="Multimídia 1"></div>
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="col-lg-12" v-if="currenturl != '/noticias'">
                <div class="btn-mais-posts text-center">
                    <router-link to="/noticias" aria-label="Mais Posts">
                        Mais posts
                    </router-link>
                </div>
            </div>
            <div class="col-lg-12" v-if="currenturl == '/noticias'">
                <div class="btn-mais-posts text-center">
                    <router-link to="/noticias" aria-label="Mais Posts" @click="activeMult = !activeMult">
                        Carregar mais multimídias
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</section>

</template>

<script>
import axios from 'axios';

const urlMult = 'https://api.cref20.org.br/wp-json/cref/v1/multimidias';

export default {
    name: 'MultimidiaVue',
    data() {
        return {
            galMultimidias: [],
            currenturl: '',
            activeMult: false
        }
    },
    mounted() {
        axios.get(urlMult).then((response) => (
            this.galMultimidias = response.data.map((mult) => ({
                slug: mult.slug_mult,
                capa: mult.capa_destaque
            }))
        ));
    },
    created() {
        this.currenturl = window.location.pathname;
        
    }
}
</script>

<style scoped>

.multimidias-home .thumbMult {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
}
.multimidias-home {
  margin-top: 5rem;
}
.multimidias-home .col-lg-4 img {
  width: 100%;
}
.multimidias-home .btn-mais-posts {
  margin-top: 30px;
}
.multimidias-home .btn-mais-posts a {
  border: 2px solid #45A047;
  padding: 15px 30px;
  border-radius: 50px;
  color: #313131;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
}
.multimidias-home .btn-mais-posts a:hover {
  background-color: #45A047;
  color: #fff;
  transition: 0.3s;
}
.multimidias-home.bgColor {
  background: rgba(238, 238, 238, 0.8);
  margin-top: 0;
}
svg {
  width: 100%;
  margin-top: 5rem;
}
@media (max-width: 765px) {
	svg {
		display: none;
	}
}
 
</style>